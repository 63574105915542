.practice-practice {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.practice-heading {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.practice-header {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.practice-caption {
  max-width: 385px;
  line-height: 24px;
}
.practice-text {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.practice-image {
  width: 12px;
  object-fit: cover;
}
@media(max-width: 767px) {
  .practice-practice {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .practice-header {
    font-size: 20px;
    line-height: 24px;
  }
  .practice-caption {
    font-size: 14px;
    line-height: 21px;
  }
}
